// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-404-js": () => import("/tmp/2c7ba72b/src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-contacto-js": () => import("/tmp/2c7ba72b/src/pages/contacto.js" /* webpackChunkName: "component---src-pages-contacto-js" */),
  "component---src-pages-index-js": () => import("/tmp/2c7ba72b/src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-nosotros-js": () => import("/tmp/2c7ba72b/src/pages/nosotros.js" /* webpackChunkName: "component---src-pages-nosotros-js" */),
  "component---src-pages-politica-privacidad-js": () => import("/tmp/2c7ba72b/src/pages/politicaPrivacidad.js" /* webpackChunkName: "component---src-pages-politica-privacidad-js" */),
  "component---src-pages-soluciones-js": () => import("/tmp/2c7ba72b/src/pages/soluciones.js" /* webpackChunkName: "component---src-pages-soluciones-js" */)
}

exports.data = () => import(/* webpackChunkName: "pages-manifest" */ "/tmp/2c7ba72b/.cache/data.json")


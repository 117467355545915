module.exports = [{
      plugin: require('/tmp/2c7ba72b/node_modules/gatsby-plugin-google-analytics/gatsby-browser.js'),
      options: {"plugins":[],"trackingId":"UA-140381968-1"},
    },{
      plugin: require('/tmp/2c7ba72b/node_modules/gatsby-plugin-hubspot/gatsby-browser.js'),
      options: {"plugins":[],"trackingCode":"6026375","respectDNT":true,"productionOnly":true},
    },{
      plugin: require('/tmp/2c7ba72b/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
